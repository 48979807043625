import React from 'react'

import './Testimonials.scss'

const DouglassPhoto = 'https://d1syj4d8txnu77.cloudfront.net/Testimonials/Douglass.png'
const KimPhoto = 'https://d1syj4d8txnu77.cloudfront.net/Testimonials/Kim.png'

const Testimonials = () => (
  <div className="testimonials">
    <div className="wrapper">
      <div className="row">
        <div className="col-md-4 order-0 order-md-0">
          <div className="comment-block">
            <p className="comment-text">
              “As an executive communications and public speaking coach, I was looking for a VR platform to add to my
              suite of client services. And I was so impressed by the capabilities of Ovation that I decided to
              integrate it into my offerings to better serve a growing roster of clients, especially those who struggle
              with public speaking anxiety.“
            </p>
            <h4>Douglass Hatcher</h4>
            <h5>Communications Coach</h5>
            <div className="profile-img">
              <img src={DouglassPhoto} />
            </div>
          </div>
        </div>
        <div className="col-md-4 order-2 order-md-1">
          <div className="comment-block">
            <p className="comment-text">
              “I've tried different public speaking apps and this one blows away the competition. For starters, the
              graphics are unrivaled. The feedback the app offers after a speech is invaluable if you're trying to
              improve your public speaking. I've also used this app as preparation for Toastmasters speeches and the
              crowd could not believe my progress.“
            </p>
            <h4>Luis Rancel</h4>
            <h5>Toastmaster</h5>
          </div>
        </div>
        <div className="col-md-4 order-1 order-md-2">
          <div className="comment-block">
            <p className="comment-text">
              “As a professor and a privileged beta tester, I can confidently say that this app is a complete
              game-changer in my classroom. When it comes to presenting in front of a live audience (after using
              Ovation), my students report increased confidence and the ability to captivate listeners by delivering
              truly impactful presentations.“
            </p>
            <h4>Professor Kim Hyatt</h4>
            <h5>Carnegie Mellon University</h5>
            <div className="profile-img">
              <img src={KimPhoto} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Testimonials
