import React from 'react'

import './SubHero.scss'

const Icon_Education = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/Icon_Education.svg'
const Icon_Conferences = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/Icon_Conferences.svg'
const Speaking_Icon = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/BlueCompany.png'
const Language_Icon = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/Language.svg'

export default ({ data }) => (
  <div className="sub-hero">
    <div className="wrapper">
      <div className="row">
        <div className="col step">
          <div className="content-block">
            <h2>Who is Ovation for?</h2>
            <div className="subhero-for-section">
              <div className="subhero-card">
                <div className="card-image">
                  <img className="edu" src={Icon_Education} />
                </div>
                <div className="card-text">Educators dedicated to graduating confident, articulate students.</div>
              </div>
            </div>
            <div className="subhero-for-section">
              <div className="subhero-card">
                <div className="card-image">
                  <img src={Speaking_Icon} />
                </div>
                <div className="card-text">Companies aiming to develop effective salespeople and leaders.</div>
              </div>
            </div>
            <div className="subhero-for-section">
              <div className="subhero-card">
                <div className="card-image">
                  <img src={Language_Icon} />
                </div>
                <div className="card-text">
                  ESL and other language learners seeking to improve conversational fluency.
                </div>
              </div>
            </div>
            <div className="subhero-for-section">
              <div className="subhero-card">
                <div className="card-image">
                  <img src={Icon_Conferences} />
                </div>
                <div className="card-text">Anyone with something to say.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
