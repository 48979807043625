import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const customSpeechImg = 'https://d1syj4d8txnu77.cloudfront.net/CustomSpeech/CustomSpeech.png'

export default class EndlessCustomization extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6 order-md-2">
          <h3 className="show-on-mobile">Endless Customization</h3>
          <StaticImage className="img" src={customSpeechImg} alt="Speech" imgStyle={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-6 order-md-1">
          <h3 className="hide-on-mobile">Endless Customization</h3>
          <h4>
            Import your own text, slides, and slide notes.
            <br />
            <br />
            Choose from dozens of interactive speech tools, including laptops, microphones, laser pointers, and
            projector screens.
            <br />
            <br />
            Adjust your audience's attire and attitude.
          </h4>
        </div>
      </div>
    )
  }
}
