import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './TrainingTools.scss'

const Tool_AttentionDist = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_AttentionDist.svg'
const Tool_SpeakingRate = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_SpeakingRate.svg'
const Tool_Pauses = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_Pauses.svg'
const Tool_Monotone = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_Monotone.svg'
const Tool_MicDistance = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_MicDistance.svg'
const Tool_HandMovement = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_HandMovement.svg'
const Tool_StareColoring = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_StareColoring.svg'
const Tool_SpeechToolStareColoring =
  'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_SpeechToolStareColoring.svg'
const Tool_SustainedEyeContact = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_SustainedEyeContact.svg'
const Tool_GazeGuiders = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_GazeGuiders.svg'
const Tool_FillerWords = 'https://d1syj4d8txnu77.cloudfront.net/TrainingTools/Tool_FillerWords.svg'

const trainingImages = {
  attention: Tool_AttentionDist,
  speakingrate: Tool_SpeakingRate,
  pauses: Tool_Pauses,
  monotone: Tool_Monotone,
  micdist: Tool_MicDistance,
  hand: Tool_HandMovement,
  stare: Tool_StareColoring,
  speechtoolstare: Tool_SpeechToolStareColoring,
  sustainedeye: Tool_SustainedEyeContact,
  gaze: Tool_GazeGuiders,
  filler: Tool_FillerWords
}

const trainingName = {
  attention: `Attention Distribution`,
  speakingrate: `Speaking Rate`,
  pauses: `Pauses`,
  monotone: `Monotone`,
  micdist: `Mic Distance`,
  hand: `Hand Movement`,
  stare: `Audience Section Staring`,
  speechtoolstare: `Speech Tool Staring`,
  sustainedeye: `Sustained Eye Contact`,
  gaze: `Gaze Guiders`,
  filler: `Filler Words`
}

const trainingText = {
  attention: `Percentages located above each side
    of the audience indicate how evenly
    the speaker is speading their attention
    during a speech.`,
  speakingrate: `If your speaking rate is too high
    or too low for an extended period of time, a
    warning will appear recommending that you
    adjust your speed.`,
  pauses: `If you fail to use a verbal pause (of at
    least 1 second) for an extended period of time,
    a warning will appear reminding you to do so.`,
  monotone: `If you fail to vary your pitch for an
    extended period of time, a warning will appear
    reminding you to do so.`,
  micdist: `The speaker’s mic will glow red and
    vibrate when it is held outside of 
    pick up range, reminding the speaker
    that their audience cannot hear them.`,
  hand: `Visual cues will alert you when your hand
    movements have remained in one location for too long. 
    These cues will encourage you to move your hands to
    other locations for more varied and active gesturing.
    `,
  stare: `Prolonged attention towards one side
    of the audience triggers a visual
    reminder to shift the speaker’s attention
    to another section.`,
  speechtoolstare: `Prolonged attention towards a speech
    tool (projector screen, note card, laptop, 
    teleprompter, etc.) triggers a visual reminder 
    to shift the speaker's attention back to the audience. 
    *Requires eye tracking.`,
  sustainedeye: `If the speaker fails to make sustained eye contact
    with an audience member for an extended period of time,
    a warning will appear reminding them to do so.
    *Requires eye tracking.`,
  gaze: `Targets, or “Guiders”, surround 
    audience member’s heads, directing
    the speaker’s attention casually around 
    the venue.`,
  filler: `You will receive warning notifications each time you say a filler word (um, like, so).`
}

export default class TrainingTools extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'filler'
    }
  }

  tableAreaClick() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-interactive-element-home-page-marketing-table-effective-tools'
    })
  }

  render() {
    const data = this.props.data
    return (
      <div className="row training-tools">
        <div className="col-sm-12 col-md-6 order-md-1">
          <h3 className="show-on-mobile">Real-Time Training</h3>
          <div className="training-tools-row row">
            <div className="training-tools-list col-sm-5" onClick={() => this.tableAreaClick()}>
              <div className="training-tools-list-divider gaze">
                <img src="/Icon_Gaze_Blue.svg" />
                Gaze
              </div>
              <div
                onClick={() => this.setState({ slide: 'stare' })}
                className={'training-tools-list-item' + (this.state.slide === 'stare' ? ' active' : '')}
              >
                Audience Section Staring
              </div>
              <div className="training-tools-list-divider voice">
                <img src="/Icon_Voice_Blue.svg" />
                Voice
              </div>
              <div
                onClick={() => this.setState({ slide: 'filler' })}
                className={'training-tools-list-item' + (this.state.slide === 'filler' ? ' active' : '')}
              >
                Filler Words
              </div>
              <div
                onClick={() => this.setState({ slide: 'speakingrate' })}
                className={'training-tools-list-item' + (this.state.slide === 'speakingrate' ? ' active' : '')}
              >
                Speaking Rate
              </div>
              <div
                onClick={() => this.setState({ slide: 'pauses' })}
                className={'training-tools-list-item' + (this.state.slide === 'pauses' ? ' active' : '')}
              >
                Pauses
              </div>
              <div
                onClick={() => this.setState({ slide: 'monotone' })}
                className={'training-tools-list-item' + (this.state.slide === 'monotone' ? ' active' : '')}
              >
                Monotone
              </div>
              <div className="training-tools-list-divider gesture">
                <img src="/Icon_Gesture_Blue.svg" />
                Hands
              </div>
              <div
                onClick={() => this.setState({ slide: 'hand' })}
                className={'training-tools-list-item' + (this.state.slide === 'hand' ? ' active' : '')}
              >
                Hand Movement
              </div>
              <div
                onClick={() => this.setState({ slide: 'micdist' })}
                className={'training-tools-list-item' + (this.state.slide === 'micdist' ? ' active' : '')}
              >
                Mic Distance
              </div>
            </div>
            <div className="training-tools-image col-sm-7">
              <div className="image-wrap">
                <img src={trainingImages[this.state.slide]} />
              </div>
              <div className="text-wrap">
                <div className="title-wrap">{trainingName[this.state.slide]}</div>
                {trainingText[this.state.slide]}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 order-md-2 text">
          <h3 className="hide-on-mobile">Real-Time Training</h3>
          <h4>
            Your performance is evaluated as you speak, with notifications informing you the moment you say a filler
            word, stare at one side of the audience, fail to gesture, and more.
            <br />
            <br />
            <a
              target="_blank"
              rel="noopener"
              href="https://youtu.be/uckWPJebs2k"
              className="gtm-outbound-link-ovation-training-in-action"
            >
              See Ovation's training in action. <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </h4>
        </div>
      </div>
    )
  }
}
