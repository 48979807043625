import React from 'react'

import './HowItWorks.scss'

import RealisticAudiencesAndVenues from '../RealisticAudiencesAndVenues/RealisticAudiencesAndVenues'
import EndlessCustomization from '../EndlessCustomization/EndlessCustomization'
import AIPoweredSpeakingScenarios from '../AIPoweredSpeakingScenarios/AIPoweredSpeakingScenarios'
import TrainingTools from '../TrainingTools/TrainingTools'
import RecordPlayback from '../RecordPlayback/RecordPlayback'
import Analytics from '../Analytics/Analytics'
import MultiplayerSession from '../MultiplayerSession/MultiplayerSession'

export default class HowItWorks extends React.Component {
  render() {
    return (
      <div className="how-it-works">
        <div className="wrapper">
          <div style={{ textAlign: 'center' }}>
            <h2>How Ovation Works</h2>
            <h4>Take a closer look at the key features enabling you to elevate your speaking skills.</h4>
          </div>
          <AIPoweredSpeakingScenarios
            openLightboxWithImageIndex={this.props.openLightboxWithImageIndex}
            carouselImages={this.props.analyticsCarouselImages}
          />
          <Analytics
            openLightboxWithImageIndex={this.props.openLightboxWithImageIndex}
            carouselImages={this.props.aiPoweredFeedbackAnalyticsImages}
          />
          <RealisticAudiencesAndVenues />
          <EndlessCustomization />
          <TrainingTools />
          <RecordPlayback />
          <MultiplayerSession />
        </div>
      </div>
    )
  }
}
