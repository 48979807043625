import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './WirelessVR.scss'

const SharingImage = 'https://d1syj4d8txnu77.cloudfront.net/OvationQuest/OvationQuest2.jpg'

const WirelessVR = () => (
  <div className="wireless-vr">
    <div className="wrapper">
      <div className="row position-relative">
        <div className="col-md-6 order-md-1">
          <h2>Designed for Wireless VR</h2>
          <h4>
            Ovation is compatible with Meta Quest 2, Quest 3, Quest 3S, and Quest Pro headsets.
            <br />
            <br />
            If you already own a compatible headset, visit{' '}
            <a
              target="_blank"
              rel="noopener"
              href="https://www.oculus.com/experiences/quest/4510688418969375/"
              className="gtm-outbound-link-ovation-meta-app-store"
            >
              Ovation's Meta App Store page <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>{' '}
            to add Ovation to your App Library.
          </h4>
          <br />
          <a
            className="signup-button hide-on-mobile"
            target="_blank"
            href="https://www.oculus.com/experiences/quest/4510688418969375/"
          >
            Get for Meta Quest
          </a>
        </div>

        <a
          className="signup-button show-on-mobile"
          target="_blank"
          href="https://www.oculus.com/experiences/quest/4510688418969375/"
        >
          Get for Meta Quest
        </a>

        <br />

        <div className="col-md-6 order-md-2 text-center">
          <img src={SharingImage} />
        </div>
      </div>
    </div>
  </div>
)

export default WirelessVR
