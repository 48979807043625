import React, { useEffect, useRef } from 'react'

const WebsiteMultiplayerVideo = 'https://d1syj4d8txnu77.cloudfront.net/MultiplayerSession/Website_Multiplayer.mp4'

const MultiplayerSession = () => {
  const videoRef = useRef()

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, [])

  return (
    <div className="row record-playback">
      <div className="col-md-6">
        <h3 className="show-on-mobile">Multiplayer</h3>
        <div className="headset-wrap">
          <video ref={videoRef} muted loop playsInline src={WebsiteMultiplayerVideo} />
        </div>
      </div>
      <div className="col-md-6">
        <h3 className="hide-on-mobile">Multiplayer</h3>
        <h4>
          Meet up with others virtually in Ovation, no matter where you are.
          <br />
          <br />
          Practice a group speech with classmates, rehearse a meeting with a colleague, or get guidance on your keynote
          from a professional coach.
        </h4>
      </div>
    </div>
  )
}

export default MultiplayerSession
