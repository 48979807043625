import React from 'react'

import './OvercomeYourFear.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/FlexibleConcurrentLicensing.jpg'

export default class OvercomeYourFear extends React.Component {
  render() {
    return (
      <div className="overcome-your-fear">
        <div className="wrapper">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-md-6 order-md-2">
              <h2 className="show-on-mobile mobile-center">Overcome Your Fear with Ovation</h2>
              <div className="overcome-iframe-wrapper">
                <iframe
                  src="https://www.youtube.com/embed/d-YcZU8t9AU"
                  title="How to Overcome Fear of Public Speaking with Virtual Reality"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6 order-md-1 mobile-center">
              <h2 className="hide-on-mobile">Overcome Your Fear with Ovation</h2>
              <h4>
                Join Alex Lyon, YouTube's top public speaking expert, as he explores the research behind the
                transformative power of VR training in conquering anxiety and building confidence.
              </h4>
              <br />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
