import React from 'react'

export default class RealisticAudiencesAndVenues extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6 order-md-1">
          <h3 className="show-on-mobile">Realistic Audiences & Venues</h3>
          <div className="video-wrapper">
            <div className="video-iframe">
              <iframe
                src="https://www.youtube.com/embed/7TP6VHB1naw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-md-6 order-md-2">
          <h3 className="hide-on-mobile">Realistic Audiences & Venues</h3>
          <h4>
            Audience members are real people scanned into 3D models. They smile, clap, ask questions, and get distracted
            by their phones.
            <br />
            <br />
            An ever-expanding collection of venues includes conference halls, classrooms, and boardrooms — all based on
            real-world locations.
          </h4>
        </div>
      </div>
    )
  }
}
